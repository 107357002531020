
import { Options, Prop, Vue, Watch } from "vue-property-decorator";

import Page from "@views/page/components/page.vue";
import LogsView from "@views/page/logs-view.vue";
import ViewFilter from "@views/page/view-filter.vue";
import PageRequest from "@dataSource/PaginationHelper/Lib/PageRequest";
import FilterModel from "../components/filter-model";
import getUtc from "@/filters/getUtc";

@Options<LogsViewPage>({
  components: {
    Page,
    ViewFilter,
    LogsView,
  },
})
export default class LogsViewPage extends Vue {
  @Prop({ default: null }) slotProps: {
    sourceLogId: Number;
    filterChange: Boolean;
  };

  pagination: PageRequest;
  filterModel: FilterModel = new FilterModel();
  getUtc = getUtc;
  sourceId = -1;

  filterData() {
    (this.$refs.page as LogsView).onFilterChange();
  }

  setSourceId(value: number) {
    this.sourceId = value;
  }

  convertUTCDateToLocalDate(date) {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }

  addMinutes(date, minutes) {
    return new Date(new Date(date).getTime() + minutes * 60000);
  }
}
