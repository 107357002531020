<template>
  <page name="Просмотр логов методов" :selectSource="false">
    <template v-slot:default="slotProps">
      <filter-component
        v-model:filter="filterModel"
        @filterData="filterData"
        :selectInterval="false"
        :methods="true"
        :logsType="false"
        :url="false"
        :loggerName="false"
        :shortMessage="false"
      ></filter-component>
      <!-- {{ setSourceId(slotProps.sourceLogId) }}
      <view-filter
        v-model:filter="filterModel"
        :logsType="false"
        :methods="true"
      >
      </view-filter> -->
      <logs-view :filter="filterModel" ref="page"></logs-view>
      <bs-row v-if="!filterModel">
        <bs-col>
          <div class="set-filter">Нажмине кнопку отфильтровать!</div>
        </bs-col>
      </bs-row>
    </template>
  </page>
</template>

<script lang="ts">
import { Options, Prop, Vue, Watch } from "vue-property-decorator";

import Page from "@views/page/components/page.vue";
import LogsView from "@views/page/logs-view.vue";
import ViewFilter from "@views/page/view-filter.vue";
import PageRequest from "@dataSource/PaginationHelper/Lib/PageRequest";
import FilterModel from "../components/filter-model";
import getUtc from "@/filters/getUtc";

@Options<LogsViewPage>({
  components: {
    Page,
    ViewFilter,
    LogsView,
  },
})
export default class LogsViewPage extends Vue {
  @Prop({ default: null }) slotProps: {
    sourceLogId: Number;
    filterChange: Boolean;
  };

  pagination: PageRequest;
  filterModel: FilterModel = new FilterModel();
  getUtc = getUtc;
  sourceId = -1;

  filterData() {
    (this.$refs.page as LogsView).onFilterChange();
  }

  setSourceId(value: number) {
    this.sourceId = value;
  }

  convertUTCDateToLocalDate(date) {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }

  addMinutes(date, minutes) {
    return new Date(new Date(date).getTime() + minutes * 60000);
  }
}
</script>

<style lang="less">
.logs-view-page {
  .min-width {
    min-width: 150px;
  }
}
.set-filter {
  display: flex;
  justify-content: center;
  color: orange;
  font-weight: bold;
  font-size: 18px;
}
</style>